
































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Playlist, Tag} from '@/types';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import {RawLocation} from "vue-router";

@Component<TagTableList>({
  components: {
    UTable,
  }
})
export default class TagTableList extends Vue {
  @Prop({required: true, type: Array})
  private list!: Tag[];

  private get fields(): TableField[] {
    return [
      {
        name: 'id',
        label: 'ID',
        width: '65px',
        sortable: true,
      },

      {
        name: 'name',
        label: 'Название',
        sortable: true,
      },

      {
        name: 'trackCount',
        label: 'Треки',
        align: 'right',
        width: '100px',
        sortable: true,
      },
    ];
  }

  private itemRoute(pl: Tag): RawLocation {
    return {
      name: 'tagItem',
      params: {
        id: pl.id.toString(),
      },
    };
  }

  private itemTracksRoute(pl: Playlist): RawLocation {
    return {
      name: 'tagItemTracks',
      params: {
        id: pl.id.toString(),
      },
    };
  }
}
