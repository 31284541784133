















































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Tag} from '@/types';
import {TagService} from '@/api';
import UButton from '@/ui-lib/button/Button.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import UPagination from '@/ui-lib/pagination/Pagination.vue';
import {Route} from "vue-router";
import UTextField from "@/ui-lib/text-field/TextField.vue";
import {debounce} from "@/utils/debounce";
import {RequestOptions} from "@/api/api";
import TagTableList from "@/components/tag/TagTableList.vue";

const limit = 30;

@Component<TagListView>({
  components: {
    UTextField,
    UPagination,
    UBlock,
    UButton,
    TagTableList,
  },

  metaInfo() {
    return {
      title: 'Теги',
    };
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = TagService.prepareListParams(to, limit);
      const {list, total} = await TagService.list(params);

      next((vm) => {
        vm.list = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      if (this.abortList) {
        this.abortList.abort()
      }

      this.abortList = new AbortController();
      await this.updateList(to, {signal: this.abortList.signal});
      next();
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        next()
      } else {
        next(e);
      }
    }
  },
})
export default class TagListView extends Vue {
  private total = 0;
  private list: Tag[] = [];
  private search = '';
  private abortList: null | AbortController = null;

  @Watch('search')
  private searchChanged = debounce((val: string) => {
    this.$router.replace({
      query: {
        ...this.$route.query,
        start: undefined,
        search: val ? val : undefined,
      },
    })
  }, 300)

  @Watch('$route')
  private routeChanged(val: Route) {
    if (!('search' in val.query)) {
      this.search = '';
    }
  }

  private get params() {
    return TagService.prepareListParams(this.$route, limit);
  }

  private get limit() {
    return this.params.limit;
  }

  private get start() {
    return this.params.start;
  }

  private changeStart(newStart: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        start: newStart.toString(),
      },
    });
  }

  private async updateList(route: Route, opts?: RequestOptions) {
    const params = TagService.prepareListParams(route, limit);
    const {list, total} = await TagService.list(params, opts);

    this.list = list;
    this.total = total;
  }
}
